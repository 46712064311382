import logo from './IMG_1176.JPG';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className="App-header">
          da fuq u doing here??
        </p>

        <p className="description">
          the apollonius corporation is a family office investment firm focused on growing the value of our family wealth. 
        </p>
        <p className="hello">if you are doing kyc, hello! we're excited to work together. 
        <br/>
        else: hello, as well, but you're likely wasting your time, fren.</p>
      </header>
    </div>
  );
}

export default App;
